<template>
  <Default>
    <div
      class="login d-flex justify-content-center justify-content-md-end align-items-center p-2 pmd-5 position-relative"
    >
      <form @submit.prevent="login()" class="card card--login py-3 px-4">
        <div class="card-body">
          <div class="d-flex justify-content-center">
            <img :src="logo" alt="" class="login__logo" />
          </div>

          <h1 class="login__title font-weight-bold mt-3">Ingresar</h1>

          <div class="form-group">
            <label for="email">Correo electrónico</label>
            <input
              type="email"
              id="email"
              required
              class="form-control"
              v-model="email"
            />
          </div>

          <div class="form-group">
            <label for="password">Contraseña</label>
            <input
              type="password"
              id="password"
              required
              class="form-control"
              v-model="password"
            />
          </div>

          <p class="small text-danger text-center" v-if="error">{{ error }}</p>

          <div class="text-right">
            <button
              type="submit"
              class="btn btn-primary px-4"
              :disabled="loading || !validar"
            >
              <template v-if="loading">
                <span
                  class="spinner-grow spinner-grow-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span class="sr-only">Ingresando</span>

                <span class="d-inline-block ml-2">Cargando</span>
              </template>

              <span v-else>Ingresar</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  </Default>
</template>

<script>
import login from "@/apollo/mutations/auth/login.gql";
import Default from "@/layouts/default.vue";
export default {
  data() {
    return {
      logo: require("@/assets/imagenes/logorayro.svg"),
      email: null,
      password: null,
      loading: false,
      error: null,
    };
  },
  components: {
    Default,
  },
  methods: {
    login() {
      this.loading = true;
      this.error = null;

      let { email, password } = this;

      this.$apollo
        .mutate({
          mutation: login,
          variables: {
            input: {
              email,
              password,
            },
          },
          errorPolicy: "all",
        })
        .then((response) => {
          if (response.errors) {
            let codigoError = response.errors[0].debugMessage;

            if (codigoError === "NO_EXISTE") {
              this.error = "No existe usuario registrado.";
            }

            if (codigoError === "CONTRASEÑA_INCORRECTA") {
              this.error = "Contraseña incorrecta";
            }

            this.loading = false;

            return;
          }

          let { nombres, apellidos, apiToken } = response.data.login;
          let datosAGuardar = JSON.parse(JSON.stringify(response.data.login));

          delete datosAGuardar.apiToken;

          this.$cookies.set("apiquimi_token", apiToken);
          this.$cookies.set("apiquimi_user", datosAGuardar);

          this.$store.commit("SET_USUARIO_LOGUEADO", true);
          this.$store.commit("SET_DATOS_USUARIO_LOGUEADO", datosAGuardar);

          this.loading = false;

          this.$toast.success(`Bienvenido ${nombres} ${apellidos}`, {
            // override the global option
            position: "top-right",
          });

          if (datosAGuardar.tipoUsuario != 1) {
            this.$router.push({ name: "asignaciones" });
          } else {
            this.$router.push({ name: "asignaciones" });
          }
        });
    },
  },
  computed: {
    validar() {
      let { email, password } = this;
      let status = false;

      if (email && password) {
        status = true;
      }
      return status;
    },
  },
};
</script>

<style lang="scss">
.login {
  width: 100vw;
  height: 100vh;

  background-image: url("https://empresas.infoempleo.com/hrtrends/media/2017/04/C%C3%B3mo-potenciar-el-Team-building-en-tu-empresa-1024x683.jpg");
  background-size: cover;
  background-repeat: no-repeat;

  &::before {
    content: "";

    background-color: rgba($dark, 0.3);

    width: 100%;
    height: 100%;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__logo {
    max-width: 200px;
  }

  &__title {
    font-size: 1.5em;
  }

  .card--login {
    width: 100%;

    position: relative;
    z-index: 100;

    @media (min-width: 758px) {
      width: 400px;

      margin-right: 3rem;
    }
  }
}
</style>
